import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import React from "react";

import { AuthProvider } from "./Auth";

import { GlobalStyle } from './styles/global';

import { theme } from './styles/theme';
import SignIn from "./pages/SignIn";
import NoMatchPage from './pages/NoMatchPage';
import PasswordReset from "./pages/PasswordReset";
import SignUp from "./pages/SignUp";
import Menu from "./pages/Menu";

const App = () => {
    return (
        <AuthProvider>
            <ThemeProvider theme={ theme }>
                <GlobalStyle />

                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/signin" />
                        </Route>

                        <Route exact path="/signin">
                            <SignIn />
                        </Route>

                        <Route exact path="/signup">
                            <SignUp />
                        </Route>

                        <Route exact path="/passwordReset">
                            <PasswordReset />
                        </Route>

                        <Route exact path="/:id">
                            <Menu />
                        </Route>

                        <Route path="*">
                            <NoMatchPage />
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </AuthProvider>
    );
};

export default App;
