import React from 'react';
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';

import { Centered } from '../../src/styles/global';
import Body02 from '../components/common/typography/Body02';
import Headline06 from '../components/common/typography/Headline06';
import Headline01 from '../components/common/typography/Headline01';
import { loginRoute } from '../helpers/routes';

const NoMatchPage = () => {
    const theme = useTheme();

    return (
        <Centered>
            <Root>
            <Headline01 bold center>
                404
            </Headline01>
            <Headline06 bold center>
               Account not found
            </Headline06>

            <Body02 color={ theme.textSecondary }>
                Are you a store owner?  <a href="https://reaction.menu#contacts" target="blank">Contact us</a>
            </Body02>

            <StyledLink to={loginRoute}>Sign in</StyledLink>
            </Root>
        </Centered>
    );
}

export default NoMatchPage;

const Root = styled.div`
	display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`;

const StyledLink = styled(Link)`
	margin-top: 20px;
`;