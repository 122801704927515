import styled from 'styled-components';

const Headline01 = styled.h1`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 46px;
    font-family: extraBold;
    letter-spacing: -0.5px;
    line-height: 58px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        font-size: 76px;
        line-height: 92px;
    }
`;

export default Headline01;
