import { CSSTransition, TransitionGroup } from 'react-transition-group'; 
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import ReactDOM from "react-dom";
import styled from 'styled-components';

import { ReactComponent as CancelIcon } from '../../img/close.svg';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import Block from '../common/Block';
import Icon from '../common/Icon';
import Text from '../common/Text';

const JSX_MODAL = ({
    children,
    onClose,
    title,
    open,
    close
}) => {
    const { t } = useTranslation();

    const ref = useRef();

    const handleClick = (event) => {
        if (ref.current.contains(event.target)) {
            event.stopPropagation();

            return;
        }

        onClose();
    };

    useLockBodyScroll(open);

    return (
        <TransitionGroup>
            { open && (
                <CSSTransition
                    timeout={ 300 }
                    classNames="modalTransition"
                    unmountOnExit
                >
                    <Window onClick={ handleClick }>
                        <Root ref={ ref } className="modalTransition">

                          { title &&  
                                <Header center noBorder>
                                    <Text bold>{ title }</Text>

                                    <CloseIcon onClick={ onClose } 
                                        icon={ <CancelIcon /> }
                                        size={ 24 }
                                    />
                                </Header> 
                            }  

                            { close &&
                                <CloseIcon onClick={ onClose } 
                                    background
                                    icon={ <CancelIcon /> }
                                    size={ 24 }
                                />
                            } 

                            <Content title={ title }>{ children }</Content>
                        </Root> 
                    </Window>
                </CSSTransition>
            ) }
        </TransitionGroup>
    );
}

function ModalBottom(props) {
    return ReactDOM.createPortal(<JSX_MODAL { ...props } />, document.querySelector("#modal"));
};

export default ModalBottom;

const CloseIcon  = styled(Icon)`
    position: absolute;
    right: 4px;
    z-index: 10;
`;

const Content  = styled.div`
    padding-bottom: 55px;
`;

const Header  = styled(Block)`
    align-items: center;
    border-bottom: 1px solid ${ ({ theme }) => theme.border };
    border-radius: 8px 8px 0 0;
    height: 56px;
`;

const Root  = styled.div`
    background-color: ${ ({ theme }) => theme.body };
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
    margin: 0 auto;
    position: relative;
    width: ${ ({ theme }) => theme.tabletBreakpoint };

	 @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 100%;
    }
`;

const Window  = styled.div`
    align-items: center;
    background-color: ${ ({ theme }) => theme.overlay };
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    left: 0;
    padding-top: 64px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;

    &.modalTransition-enter {
        opacity: 0;

        ${ Root } {
            transform: translateY(100%);
        }
    }

    &.modalTransition-enter-active {  
        opacity: 1;
        transition: ${ ({ theme }) => theme.transition };

        ${ Root } {
            transform: translateY(0);
            transition: ${ ({ theme }) => theme.transition };
        }
    }

    &.modalTransition-exit {
        opacity: 1;

        ${ Root } {
            transform: translateY(0);
        }
    }
    
    &.modalTransition-exit-active {  
        transition: ${ ({ theme }) => theme.transition };
        opacity: 0;
        
        ${ Root } {
            transform: translateY(100%);
            transition: ${ ({ theme }) => theme.transition };
        }
    }
`;
