import { withRouter } from "react-router";
import React, { useCallback, useState } from "react";
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';

import { loginRoute } from '../helpers/routes';
import Button from '../components/common/Button';
import db from "../config/firebase";
import Input from '../components/common/Input';
import Body02 from '../components/common/typography/Body02';
import Headline06 from '../components/common/typography/Headline06';
import Body01 from '../components/common/typography/Body01';

const PasswordReset = ({ history }) => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    const theme = useTheme();

	const handlePasswordReset = useCallback(
		async event => {
            event.preventDefault();
            setLoading(true);

			const { email } = event.target.elements;

			try {
				await db
				.auth()
                .sendPasswordResetEmail(email.value);

				history.push(loginRoute);
			} catch (error) {
                setLoading(false);
				setError(error.message);
			}
		},
	[history]
);

    return (
        <Root>
            <Headline06 bold>
                Reset Password
            </Headline06>

            <StyledBody02 color={ theme.textSecondary }>
                Check your email after submitting
            </StyledBody02>

            <Form onSubmit={ handlePasswordReset }>
                <Input autoFocus
                    name="email"
                    type="email"
					label="Email"
                    placeholder="Type your email"
                    id="Email"
                />
                    
                {error && <Body01 color={ theme.red }>{ error }</Body01>}

                <StyledButton label="Submit"
                    type="submit"
                    primary
                    loading={ loading }
                />
            </Form>

            <StyledBody02 color={ theme.textSecondary}>or <StyledLink to={ loginRoute }>Back to Sign in</StyledLink></StyledBody02>
        </Root>
    );
};

export default withRouter(PasswordReset);

const StyledButton  = styled(Button)`
   margin-top: 12px;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: 80%;
        margin: 0 auto;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

const StyledBody02 = styled(Body02)`
    margin: 24px auto;
`;

const StyledLink = styled(Link)`
    text-decoration: underline;
`;
