import { withRouter } from 'react-router';
import db from '../config/firebase';
import React, { useState } from 'react';
import styled, {useTheme} from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as CancelIcon } from '../img/close.svg';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import Headline06 from '../components/common/typography/Headline06';
import Body01 from '../components/common/typography/Body01';
import Body02 from '../components/common/typography/Body02';
import Icon from '../components/common/Icon';
import { landingRoute } from '../helpers/routes';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ displayName, setDisplayName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ passwrd, setPassword ] = useState('');

    const history = useHistory();
    const theme = useTheme();

	const handleSignUp = async event => {
            event.preventDefault();
            setLoading(true);

			const { email, password } = event.target.elements;

			try {
				const { user } = await db
				.auth()
				.createUserWithEmailAndPassword(email.value, password.value);
                
                if (user) {
                    const uid = db.auth().currentUser.uid;
                    const userDocRef = db.firestore().collection('users').doc(uid);
    
                    userDocRef
                    .set({
                        displayName: displayName
                    })
                    
				    history.push(`/${ user.uid }`)
			    }
            } catch (error) {
				setLoading(false);
                setError(error.message);
			}
};

    return (
        <>
        <Root>
            <a href={ landingRoute }><CloseIcon icon={ <CancelIcon /> }/></a>

            <Headline06 bold>
                Join us
            </Headline06>

            <StyledBody02 color={ theme.textSecondary } center>Share a little about yourself to help us work better together!</StyledBody02>

            <Form onSubmit={ handleSignUp }>
                <Input 
                    name="displayName"
                    type="text"
                    value={ displayName }
                    onChange={ (e) => setDisplayName(e.target.value) }
                    label="Business name"
                    id="Business name" 
                    placeholder="Business name" 
                />

                <Input name="email"
                    type="email"
                    value={ email }
                    label="Email"
                    id="Email" 
                    onChange={ (e) => setEmail(e.target.value) }
                    placeholder="Type your email"
                />

                <Input name="password"
                    type="text"
                    value={ passwrd }
                    label="Password"
                    id="Password" 
                    onChange={ (e) => setPassword(e.target.value) }
                    placeholder="Create password"
                />

                {error && <Body01 color={ theme.red }>{ error }</Body01>}

                <StyledButton label={ loading ? "Creating..." : "Create account" }
                    type="submit"
                    primary
                    loading={ loading }
                    width="100%"
                />
            </Form>

            <StyledBody02 color={ theme.textSecondary}>Already have an account? <StyledLink to="/signin">Login</StyledLink> to continue</StyledBody02>
        </Root>
        </>
    );
};

export default withRouter(SignUp);

const CloseIcon  = styled(Icon)`
    position: fixed;
    right: 0;
    top: 0;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;


const StyledButton  = styled(Button)`
   margin-top: 12px;
`;

const StyledLink = styled(Link)`
    color: ${ ({ theme }) => theme.text };
    text-decoration: underline;
    font-family: bold;
`;

const StyledBody02 = styled(Body02)`
    margin: 24px auto;
`;

const Root = styled.div`
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        justify-content: flex-start;
        margin: 80px auto;
        width: 80%;
        padding-bottom: 80px;
    }
`;
