import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../Auth';
import AddButton from './AddButton';
import SettingsButton from './settings/SettingsButton';
import Link from '../../components/common/Link';
import firebase from 'firebase';
import LoadingSpinner from '../common/Loadings/LoadingSpinner';

const HeaderOfUser = ({
    userId,
    businessName,
    phone,
    lang,
    logo,
    userTheme
}) => {
    const categories = useSelector(state => state.categories.categories);
    
    const theme = useTheme();

    const { t } = useTranslation();

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const user = currentUser?.uid === userId;

    const handleGoToSubscription = async () => {
        setLoading(true);
        setError(false);

        try {
            const functionRef = firebase
                .app()
                .functions("europe-central2")
                .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
            
            const result = await functionRef({
                returnUrl: window.location.origin,
            });

            if (result && result.data) {
                window.open(result.data.url, '_blank');
            } else {
                setError(true);
            }
        } catch (err) {
            alert('Subscription portal error:', err);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    if (user) {
        return (
            <Root>
                <SettingsButton  businessName={ businessName } 
                    logo={ logo }
                    userTheme={ userTheme }
                    phone={ phone } 
                />

                { loading ? <LoadingSpinner color={ theme.body } />
                    : <Link text={ error ? 'Error! Contact us' : t('Manage subscription') }
                        onClick={ handleGoToSubscription }
                        small 
                        color={ theme.body }
                    />
                }

                <StyledAddButton categories={ categories } 
                    lang={ lang }
                />
            </Root>
    )}

    return null
    ;
}

export default HeaderOfUser;

const Root  = styled.div`
    background-color: ${ ({ theme }) => theme.text };
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    transition: all 400ms ease;
    width: 100%;
    z-index: 3;
`;

const StyledAddButton = styled(AddButton)`
`;
